import React from 'react';
import { useIntl } from 'react-intl-next';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';

import { HELP } from '@confluence/onboarding-helpers/entry-points/constants/onboarding-state-constants';
import { OnboardingNudge } from '@confluence/onboarding-nudge-tooltip';
import { useOnboardingTrackerEligible } from '../hooks/useOnboardingTrackerEligible';

import { i18n } from '../OnboardingTrackerI18n';

import { useTrackerSpotlights } from './useTrackerSpotlights';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Padding = styled.div({
	paddingLeft: token('space.100', '8px'),
});

export const OnboardingTrackerNavHelpSpotlight = ({
	children,
	targetOnClick,
}: {
	children: React.ReactNode;
	targetOnClick: () => void;
}) => {
	const { formatMessage } = useIntl();
	const { isOnboardingTrackerExperimentEligible } = useOnboardingTrackerEligible();
	const [{ isAppNavHelpSpotlightActive }, { closeAppNavHelpSpotlight }] = useTrackerSpotlights();

	const handleTargetOnClick = () => {
		void closeAppNavHelpSpotlight();
		targetOnClick();
	};

	return isOnboardingTrackerExperimentEligible && isAppNavHelpSpotlightActive ? (
		<OnboardingNudge
			hidden={!isAppNavHelpSpotlightActive}
			setHidden={() => null}
			content={formatMessage(i18n.trackerSpotlightHelpNavContent)}
			actionSubjectId={HELP}
			source="onboardingTracker"
			wrapper={Padding}
			width={275}
			offset={[3, 8]}
			position="bottom-end"
			onClick={handleTargetOnClick}
			onOutsideClick={closeAppNavHelpSpotlight}
			onShow={() => {}}
			renderChildrenWhileLoading
		>
			{children}
		</OnboardingNudge>
	) : (
		<>{children}</>
	);
};
