import { gridSize } from '@atlaskit/theme/constants';

export const HELP_PANEL_VERSION = '1';
export const HELP_PANEL_ID = 'in-product-help';
export const HELP_PANEL_WIDTH = gridSize() * 46;

export const FEEDBACK_FIELDS_ID = {
	summary: 'summary',
	wasThisHelpful: 'customfield_10081',
	description: 'description',
	reason: 'customfield_10082',
	title: 'customfield_10083',
	contentId: 'customfield_10084',
	contentLink: 'customfield_10088',
	feedbackContext: 'customfield_10047',
	routeGroup: 'customfield_10085',
	routeName: 'customfield_10086',
	customerName: 'customfield_10045',
	email: 'email',
	contactMe: 'customfield_10043',
};

export const WAS_THIS_HELPFUL_OPTIONS = {
	yes: {
		id: '10182',
		label: 'yes',
	},
	no: {
		id: '10183',
		label: 'no',
	},
};

export const FEEDBACK_REASON_OPTIONS: { [index: string]: any } = {
	noAccurate: {
		id: '10184',
		label: 'It wasn’t accurate',
	},
	noClear: {
		id: '10185',
		label: 'It wasn’t clear',
	},
	noRelevant: {
		id: '10186',
		label: 'It wasn’t relevant',
	},
};

export const CAN_BE_CONTACTED_OPTIONS = {
	yes: {
		id: '10109',
		label: 'yes',
	},
	no: {
		id: '10111',
		label: 'no',
	},
};

export const EMBEDDABLE_KEY = 'afae180b-eb61-43c5-bc3f-5f65349a8853';
export const REQUEST_TYPE_ID = '279';
