import type { FC } from 'react';
import React, { Fragment, useContext, useCallback, useState, useRef } from 'react';
import { styled } from '@compiled/react';
import type { WrappedComponentProps } from 'react-intl-next';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl-next';

import { token } from '@atlaskit/tokens';
import { Help as AkHelp } from '@atlaskit/atlassian-navigation';
import { useAnalyticsEvents } from '@atlaskit/analytics-next/useAnalyticsEvents';
import QuestionCircleIcon from '@atlaskit/icon/core/migration/question-circle';
import { P300 } from '@atlaskit/theme/colors';
import { SpotlightTarget } from '@atlaskit/onboarding';

import { OnboardingTrackerNavHelpSpotlight } from '@confluence/experiment-onboarding-tracker/entry-points/spotlights/OnboardingTrackerNavHelpSpotlight';
import {
	VIEW_IN_PRODUCT_HELP_EXPERIENCE,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';
import { HELP } from '@confluence/onboarding-helpers/entry-points/constants/onboarding-state-constants';
import { OnboardingCTANudge } from '@confluence/onboarding-nudge-tooltip';
import { SSRMouseEventWrapper, SSR_NAV_HELP_METRIC } from '@confluence/browser-metrics';
import { RoutesContext } from '@confluence/route-manager/entry-points/RoutesContext';
import { FeedbackCollector } from '@confluence/feedback-collector';
import { preloadHelpMenu } from '@confluence/nav-menus/entry-points/preloadHelpMenu';
import { KeyboardShortcutsDialog } from '@confluence/keyboard-shortcuts-dialog';
import { START_TOUCH } from '@confluence/navdex';
import {
	GeneralShortcutListener,
	HELP_SHORTCUT,
	HELP_SHORTCUT_IN_EDIT,
	SUBMIT_FEEDBACK_SHORTCUT,
} from '@confluence/shortcuts';
import {
	HELP_PANEL_ID,
	HELP_PANEL_WIDTH,
	getRouteData,
	useInProductHelpActions,
} from '@confluence/in-product-help';
import { useRightSidebarContext } from '@confluence/page-layout-context';
import { useSessionData } from '@confluence/session-data';
import { useSSRPlaceholderReplaceIdProp, LoadableLazy } from '@confluence/loadable';
import { fg } from '@confluence/feature-gating';
import { useLivePageMode } from '@confluence/live-pages-utils/entry-points/useLivePagesStore';
import { useIsEditorPage } from '@confluence/route-manager/entry-points/useIsEditorPage';

import type { MenuComponentProps } from './menuTypes';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const QuestionCircleIconContainer = styled.span({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > span': {
		font: token('font.body.large'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'& > svg': {
			fill: token('color.icon.discovery', P300),
		},
	},
});

const i18n = defineMessages({
	help: {
		id: 'app-navigation.help.tooltip',
		defaultMessage: 'Help',
		description: 'Tooltip for the help menu',
	},
	onboardingNudgeBody: {
		id: 'app-navigation.onboarding.nudge.body',
		defaultMessage: 'Find additional help by selecting the {helpEmoji} icon.',
		description: 'Tooltip for the help menu',
	},
});

const HelpMenuLoader = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-confluencenav-menusentry-pointsHelpMenu" */ '@confluence/nav-menus/entry-points/HelpMenu'
			)
		).HelpMenu,
});

const Help: FC<MenuComponentProps & WrappedComponentProps> = ({
	openDialog,
	onClose: onCloseCallback,
	onClick,
	intl,
	isOpen: isHelpComponentOpen,
}) => {
	const triggerButtonRef = useRef(null);
	const ssrPlaceholderIdProp = useSSRPlaceholderReplaceIdProp();
	const { isLoggedIn } = useSessionData();
	const isNotesEnabled = isLoggedIn && fg('confluence_notes');
	const rightSidebarContext = useRightSidebarContext();
	const inProductHelpContext = useInProductHelpActions();
	const experienceTracker = useContext(ExperienceTrackerContext);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { match, location } = useContext(RoutesContext);
	const [isInProductHelpOpen, setIsInProductHelpOpen] = useState(false);
	const [{ isEditMode: isLiveEditMode }] = useLivePageMode();
	const isOnEditRoute = useIsEditorPage();

	// if both apps are enabled, isOpen prop may not have the most up to date state
	if (isNotesEnabled) {
		isHelpComponentOpen = rightSidebarContext.getCurrent()?.panelId === HELP_PANEL_ID;
	}

	const onClose = useCallback(() => {
		onCloseCallback();
	}, [onCloseCallback]);

	const openFeedbackCollector = () =>
		openDialog((onClose) => <FeedbackCollector onClose={onClose} />);
	const openShortcutsDialog = () =>
		openDialog((onClose) => <KeyboardShortcutsDialog onClose={onClose} />);

	const handleClick = useCallback(
		() => {
			onClick();
			// Check first if we're still in an "open" state from external contexts
			if (isHelpComponentOpen || isInProductHelpOpen) {
				// Check if the user had closed it via the IPH "X" button
				// -> No sidebar component open OR sidebar is not open with Help component
				if (
					(!rightSidebarContext.getCurrent() ||
						rightSidebarContext?.getCurrent()?.panelId !== HELP_PANEL_ID) &&
					location
				) {
					rightSidebarContext.open(HELP_PANEL_ID, HELP_PANEL_WIDTH);
					rightSidebarContext?.setTriggerButtonRef?.(triggerButtonRef);
				} else {
					setIsInProductHelpOpen(false);
					rightSidebarContext.close(HELP_PANEL_ID);
					onClose();
				}
			} else if (location) {
				experienceTracker.start({
					name: VIEW_IN_PRODUCT_HELP_EXPERIENCE,
					attributes: {
						navVersion: '3',
					},
				});

				rightSidebarContext.open(HELP_PANEL_ID, HELP_PANEL_WIDTH);
				rightSidebarContext?.setTriggerButtonRef?.(triggerButtonRef);

				const { routeName, routeGroup } = getRouteData(location.pathname);

				inProductHelpContext.setRouteName(routeName);
				inProductHelpContext.setRouteGroup(routeGroup);
			}

			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					source: 'globalNavigation',
					action: 'clicked',
					actionSubject: 'navigationItem',
					actionSubjectId: 'help',
					attributes: {
						selectedItemPageContext: match ? match.name : undefined,
						navigationLayer: 'global',
						navVersion: '3',
						navdexPointType: START_TOUCH,
					},
				},
			}).fire();
		},

		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			isHelpComponentOpen,
			onClose,
			onClick,
			match,
			createAnalyticsEvent,
			rightSidebarContext,
			experienceTracker,
			inProductHelpContext,
			isInProductHelpOpen,
			location,
		],
	);

	const preloadMenu = () => {
		void preloadHelpMenu();
		void HelpMenuLoader.preload();
	};

	return (
		<SpotlightTarget name="app-navigation-help">
			<Fragment>
				<span
					data-testid="app-navigation-help"
					data-pendo-id="con-*/wiki/home-help"
					data-vc="app-navigation-help-button"
					{...ssrPlaceholderIdProp}
				>
					<OnboardingCTANudge
						position="bottom-end"
						offset={[3, 8]}
						content={
							<FormattedMessage
								{...i18n.onboardingNudgeBody}
								values={{
									helpEmoji: (
										<QuestionCircleIconContainer data-testid="nudge-icon">
											<QuestionCircleIcon label="help menu icon" />
										</QuestionCircleIconContainer>
									),
								}}
							/>
						}
						width={280}
						source="onboardingNudge"
						actionSubjectId={HELP}
						renderChildrenWhileLoading
						dataVC="app-navigation-help-onboarding"
					>
						<OnboardingTrackerNavHelpSpotlight targetOnClick={handleClick}>
							<SSRMouseEventWrapper metricName={SSR_NAV_HELP_METRIC}>
								<AkHelp
									ref={triggerButtonRef}
									onClick={handleClick}
									onMouseEnter={preloadMenu}
									onFocus={preloadMenu}
									tooltip={<FormattedMessage {...i18n.help} />}
									isSelected={isHelpComponentOpen}
									aria-label={intl.formatMessage(i18n.help)}
									aria-haspopup
									aria-expanded={isHelpComponentOpen}
								/>
							</SSRMouseEventWrapper>
						</OnboardingTrackerNavHelpSpotlight>
					</OnboardingCTANudge>
				</span>
				<GeneralShortcutListener
					accelerator={isLiveEditMode || isOnEditRoute ? HELP_SHORTCUT_IN_EDIT : HELP_SHORTCUT}
					listener={openShortcutsDialog}
					isAppNavigationShortcut
				/>
				{!isLiveEditMode && !isOnEditRoute && (
					<GeneralShortcutListener
						accelerator={SUBMIT_FEEDBACK_SHORTCUT}
						listener={openFeedbackCollector}
						isAppNavigationShortcut
					/>
				)}
			</Fragment>
		</SpotlightTarget>
	);
};

export const HelpComponent = injectIntl(Help);
