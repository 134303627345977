import { LoadableAfterPaint } from '@confluence/loadable';
import { fg } from '@confluence/feature-gating';

export const KeyboardShortcutsDialog = LoadableAfterPaint<any>({
	loader: async () => {
		if (fg('confluence_frontend_live_edit_keyboard_shortcut')) {
			return (
				await import(
					/* webpackChunkName: "loadable-KeyboardShortcutsDialog" */ './NewKeyboardShortcutsDialog'
				)
			).KeyboardShortcutsDialog;
		} else {
			return (
				await import(
					/* webpackChunkName: "loadable-KeyboardShortcutsDialog" */ './KeyboardShortcutsDialog'
				)
			).KeyboardShortcutsDialog;
		}
	},
});

export { KeyboardShortcutsDialogQuery } from './KeyboardShortcutsDialogQuery.experimentalgraphql';
